
import { Component, Vue } from 'vue-property-decorator'
import { Table } from '../../types/common'
// import { PageType } from '../../types/menu'

@Component({
  name: 'AppMenu'
})
export default class AppMenu extends Vue {
  private searchInfo = {
    menuName: ''
  }

  private tableData: Table = {
    loading: false,
    tr: [
      {
        label: '菜单名称',
        prop: 'menuName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '菜单路由',
        prop: 'url',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '菜单icon',
        prop: 'iconPath',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '菜单序号',
        prop: 'orderNum',
        minWidth: '100'
      },
      {
        label: '是否叶子节点',
        prop: 'isLeaf',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '状态',
        prop: 'status',
        minWidth: '100',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  created() {
    this.getData()
  }

  getData() {
    this.tableData.loading = true
    this.$axios.get<Array<object>>(this.$apis.menu.selectIparkMenuAppTreeList, {
      ...this.searchInfo
    })
      .then((res) => {
        this.tableData.data = res || []
        this.tableData.loading = false
      })
      .catch((err) => {
        this.tableData.loading = false
        console.log(err)
      })
  }

  // 新增
  onAdd() {
    this.$router.push({ name: 'menuAppAdd' })
  }

  // 编辑
  onUpdate(id: string) {
    this.$router.push({
      name: 'menuAppUpdate',
      params: {
        id: id
      }
    })
  }

  onDisable(menuId: string) {
    this.$axios.post(this.$apis.menu.appDisable, {
      menuId: menuId
    })
      .then(() => {
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        this.getData()
      })
      .catch(err => {
        this.$message.error(err)
      })
  }
}
